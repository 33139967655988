.footer {
    display: flex;
    padding: 1rem;
    align-items: center;
    height: 6rem;
    background-color: $color-black;
    color: white;
    font-size: 1.5rem;
    width: 100vw;

    position: absolute;
    bottom: 0;
    left: 0;

    @include respond(phone-large) {
        height: 12rem;
        flex-direction: column;
    }
    

    &__office {
        flex: 1;
        display: flex;
        flex-direction: row;

        justify-content: flex-start;
        align-items: center;

        

        &__icon {
            margin-right: 1.5rem;

            &__item {
                width: 3.5rem;
                height: 3.5rem;

               
            }
        }

        &__text {
            font-size: 1.2rem;
           

            p {
                transition: all .2s;

                &:hover {
                    color: $color-primary;
                }
            }

        }





    }


    &__copyright {
        flex: 2;

        display: flex;
        justify-content: center;
        align-items: center;
        transition: all .2s;


        &__item {
            cursor: pointer;
            margin-right: 1.5rem;
            font-weight: bold;
            text-decoration: underline;

            &:hover {
                color: $color-primary;
            }

        }
    }

    &__logobox {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;


    }

    &__img {
        width: 4rem;
        height: 4rem;

       

        
    }

    &__sidebox {
        font-size: 1.2rem;
        margin-left: .5rem;
        margin-right: 2rem;
    }

    &__logotext {
        font-weight: 400;
    }


}