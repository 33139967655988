.print-template {
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    font-family: Arial, Helvetica, sans-serif;
    

    font-size: 10px;

    &__card {
        width: 598px;
        // min-height: 100vh;
       padding: .6rem;
        // padding-top: 1rem;
    }
    

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 5px;

        border-bottom: 2px solid #b0bf1a;

        &__logobox {
            display: flex;
            justify-content: flex-start;
            align-items: center;

        }

        &__img {
            width: 35px;
            height: 35px;
        }

        &__sidebox {
            font-size: 10px;

        }

        &__text {}

        &__price {
            font-weight: bolder;

        }
    }

    &__barcode {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 250px;
        margin-bottom: 1rem;


        &__img {

            width: 250px;
            height: 30px;

        }

        &__id {
            font-size: 1.2rem;

        }
    }

    &__top {

        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        font-size: 17px;
        font-weight: bolder;
        margin-bottom: 5px;

        &__labels {
            flex: 1;

            display: flex;
            flex-direction: column;


            &__item {}
        }

        &__heading {
            flex: 3;
            padding: 10px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;


            &__main {
                width: 100%;
                font-weight: bold;
                font-size: 20px;
                text-decoration: underline;
                text-transform: uppercase;
                text-align: center;
                word-spacing: 5px;

            }

            &__sub {
                font-size: 15px;
                width: 90%;
                text-align: center;

            }
        }

        &__passport {
            flex: 1;

            border: 1px solid #000;
            display: flex;
            justify-content: center;
            align-items: center;

            height: 120px;
        }


    }

    &__section {

        &__container {}

        &__head {
            padding: 5px;
            background-color: #aaa;

        }

        &__box {
            display: flex;
            flex-wrap: wrap;

        }

    }

    &__col14 {
        width: 25%;
        height: 36px;
        padding: 2px;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        border: 1px solid #000;
        border-bottom: none;

        &__headbox {
            width: 100%;
            display: flex;
            justify-content: space-between;
        }

        &__head {
            text-transform: capitalize;

        }

        &__info {

            font-weight: bolder;

        }

        &__required {
            font-weight: bold;
            font-size: 10px;
        }

    }

    &__col13 {
        width: 33.33%;

        &__box {
            width: 100%;
            display: flex;


            &>* {
                flex: 1;

                display: flex;

                align-items: flex-end;
            }
        }

        &__info {
            height: 25px;
            font-weight: bolder;
        }

        &__container {
            width: 100%;
            padding: 2px;
            border: 1px solid #000;
            border-top: none;
        }
    }

    &__col12 {
        width: 50% !important;
    }

    &__col11 {
        width: 100% !important;
    }

    &__terms {
        display: flex;
        // justify-content: center;
        align-items: center;
        padding-top: 3px;
        padding-bottom: 3px;

        &__sign {
            display: flex;

        }

        &__input {
            display: inline;
            width: 15px;
            height: 15px;
            border: 2px solid #000;
            margin-right: 10px;
        }

        &__item {
            border: 1px solid #000;
            border-bottom: none;

            font-weight: bold;

        }


    }

    &__official {
        padding: 10px;

        border: 1px solid $color-black;
        border-top: none;
        margin-bottom: 10px;

        &__head {
            padding: 30px;
            padding-left: 2px;
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
        }

        &__box {
            width: 100%;
            display: flex;
           
            justify-content: center;
            align-items: center;

        }


        &__list {
            flex: 2.5;
            


            &__item {
                width: 100%;
                display: flex;

                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;

                &__label {
                    flex: 1;

                }

                &__box {
                    flex: 1.5;
                    height: 30px;


                    border: 1px solid #000;

                }
            }

        }

        &__passportbox {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;


        }

        &__passportitem {

            height: 100px;
            width: 100px;
            border: 1px solid #000;

            display: flex;
            justify-content: center;
            align-items: center;

        }
    }


    &__declare {
        display: flex;

        padding-top: 3px;
        padding-bottom: 3px;

        border-top: 1px solid #000;
        border-bottom: 1px solid #000;

        &__text {
            flex: 3;
        }

        &__sign {
            flex: 1;
            border: 1px solid #000;
            margin-left: 20px;
        }

        &__date {
            font-weight: bold;
            margin-left: 10px;
            margin-right: 10px;

        }


    }

    &__footer {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;

        p {}
    }

    &__btns {
        width: 50%;
        display: flex;
        justify-content: space-around;
        margin-bottom: 120px;
        margin-top: 80px;
    }
}