.header {
    width: 100vw;
    display: flex;
    padding: 1rem;
    height: 8rem;
    align-items: center;
    background-color: #121;
    color: $color-white;

    position: absolute;
    top: 0;
    left: 0;





    &__logobox {
        flex: 1;
        display: flex;
        justify-content: flex-start;
        align-items: center;


    }

    &__img {
        width: 5rem;
        height: 5rem;

        @include respond(phone-large) {

            width: 3rem;
            height: 3rem;

        }
    }

    &__sidebox {
        font-size: 1.2rem;

        @include respond(phone-large) {
            font-size: 0.8rem;
        }

        @include respond(phone-small) {
            font-size: .6rem;
        }
    }

    &__logotext {
        font-weight: 400;
    }

    &__titlebox {
        flex: 3;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    &__title {
        font-size: 3rem;
        text-transform: uppercase;
        font-weight: bold;

        color: $color-primary;

        @include respond(tab-port) {
            font-size: 2.5rem;
        }

        @include respond(phone-large) {
            font-size: 2rem;
        }

        @include respond(phone-medium) {
            font-size: 1.5rem;
        }

        @include respond(phone-small) {
            font-size: 1.2rem;
        }
    }
    &__contact {

        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: center;




        &__item {
            width: 3rem;
            height: 3rem;
            margin-right: 2rem;
            transition: all .2s;

            @include respond(tab-port) {
                width: 2.5rem;
                height: 2.5rem;
                margin-right: 1.5rem;
            }
    
            @include respond(phone-large) {
                width: 2rem;
                height: 2rem;
                margin-right: 1.5rem;
            }
    
            @include respond(phone-medium) {
                margin-right: 1rem;
            }

            &:hover {
                width: 4rem;
                height: 4rem;

            }
        }
    }


}