.row {
    width: 90%;

    @include respond(tab-land) {
        width: 100%;
    }


}

.section-book {
    min-height: 100vh;

    color: white;
    margin-top: 8rem;


    @include respond(tab-port) {
        // padding: 10rem 0;
    }
}

.book {

    // background-image: linear-gradient(to right, rgba(#000, 0.85), rgba(#111, 0.85));
    background-color: rgba($color-primary-dark, 0.2);
    min-height: 100vh;
    // background-size: 100%;
    border-radius: 3px;
    box-shadow: 0 1.5rem 4rem rgba($color-black, .2);



    &__form {

        padding: 6rem;
        padding-top: 3rem;

        @include respond(tab-land) {
            padding: 3rem;
        }

        @include respond(phone-large) {
           
            padding-top: 2rem;
        }
    }


}


.form {

    &__section {
        position: relative;
        overflow-x: hidden;

       background-color: rgba(#00c4d4, 1);
        // background-image: linear-gradient(to right, rgba(#555, 0.2), rgba(#666, 0.2)), url(../img/airline-6763903_1280.png);
        background-size: cover;
        background-position: center;
    }

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__container {
        display: flex;

        flex-wrap: wrap;

        align-content: center;

    }

    &__inputbox {
        flex: 1;

        @include respond(tab-port) {
            flex: 0 0 48%;
            margin-right: 1rem;
            margin-bottom: 1rem;
        }

        @include respond(phone-large) {
            flex: 0 0 95%;
            
        }

        @include respond(phone-medium) {
            flex: 0 0 100%;
            
        }

        &__large {
            flex: 1.4;
        }


        &__textarea {

            &--22 {
                width: 100%;
                flex: none;
                margin-top: 3rem;

                @include respond(phone-large) {
                   
                    margin-top: 0.5rem;
                    
                }

            }

            &--12 {

                width: 50%;
                flex: none;

                @include respond(tab-port) {
                    margin-right: 0;
                    margin-bottom: 0;
                }
        
                @include respond(phone-large) {
                    width: 95%;
                    margin-bottom: 1.5rem;
                    
                }
        
                

            }

        }
    }


    &__group {
        width: 45%;
        margin-bottom: 2rem;
        margin-right: 4rem;

        @include respond(tab-land) {
            margin-right: 3rem;
        }

        @include respond(tab-port) {
            width: 70%;
        }

        @include respond(phone-large) {
            width: 90%;
        }

        @include respond(phone-small) {
            width: 95%;
        }

       


        &__full {
            width: 100% !important;
        }

        &__41 {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            width: 100%;



            &__container {
                display: flex;
                width: 100%;

                display: flex;

                flex-wrap: wrap;

                align-content: center;

            }
        }

        &--radioform {
            margin-bottom: 3rem;
            // margin-top: 3rem;
            padding: 3rem;
            padding-top: 1rem;
            border: 2px double $color-white;

            &--nopadding {
                padding: 1rem;
            }
        }
    }

    &__flagselect {
        // display: none;
        width: 10rem;


    }

    &__flag {
        &-group {
            display: flex;
        }

        &-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: .7rem;

            background-color: $color-white;

            border-right: 1px solid #333;

        }

        &-item {
            margin-bottom: .5rem;
            height: 2rem;
            width: 3rem;
        }

        &-label {
            color: $color-black;
            font-size: 1rem;
            font-weight: bold;

        }

    }



    &__input {
        font-size: 1.5rem;
        font-family: inherit;
        color: black;
        padding: 1.5rem 2rem;
        border-radius: 2px;
        background-color: $color-white;
        border: none;
        border-bottom: 3px solid transparent;
        width: 95%;
        display: block;
        transition: all .3s;

        &-withflag {
            width: 85%;
        }

        &--yearpad {
            border-color: white;
        }

        @include respond(tab-port) {
            width: 100%;
        }

        &:focus {
            outline: none;
            box-shadow: 0 1rem 2rem rgba($color-black, .1);
            border-bottom: 3px solid $color-primary;
        }

        &:focus:invalid {
            border-bottom: 3px solid $color-secondary-dark;
        }

        &::-webkit-input-placeholder {
            color: $color-grey-dark-2;
            text-transform: capitalize;
        }

        
    }

    &__dropdown-input {}


    &__label {
        text-transform: uppercase;
        font-size: 1.5rem;
        font-weight: 700;
        color: $color-white;
        margin-top: .7rem;
        margin-bottom: 1rem;
        display: block;
        transition: all .3s;
        margin-bottom: 2rem;




    }

    &__input:placeholder-shown+&__label {
        /*
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
        */
    }

    &__radio {
        padding: 2rem;
        border: 3px double $color-white;
        width: 49%;

        margin-bottom: 1rem;
        margin-right: 1rem;

        @include respond(tab-port) {
            width: 100%;
            margin-bottom: 2rem;
        }
    }


    &__radio-group {
        width: 49%;
        display: inline-block;

        @include respond(tab-port) {
            width: 100%;
            margin-bottom: 2rem;
        }
    }

    &__radio-input {
        display: none;
    }

    &__radio-label {
        font-size: $default-font-size;
        cursor: pointer;
        position: relative;
        padding-left: 4.5rem;



        &--small {
            /*
            
            font-size: 1.2rem;

            height: 3rem;

            display: flex;
            justify-content: flex-start;
            align-items: center;
            */
        }
    }

    &__radio-button {
        height: 3rem;
        width: 3rem;
        border: 5px solid $color-white;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        left: 0;
        top: -.4rem;

        &--white {
            border: 5px solid $color-white;

        }




        &::after {
            content: "";
            display: block;
            height: 1.3rem;
            width: 1.3rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background-color: $color-white;
            opacity: 0;
            transition: opacity .2s;
        }

        &--white::after {
            background-color: $color-white;
        }
    }

    &__radio-input:checked~&__radio-label &__radio-button::after {
        opacity: 1;
    }

    &__btns {
        width: 80%;
        margin-top: 3rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-bottom: 5rem;

        @include respond(tab-port) {
            width: 95%;
        }

        @include respond(phone-large) {
            margin-bottom: 12rem;
           flex-direction: column;

           & > * {
            margin-bottom: 2.5rem;
           }

        }
    }
}


// DATE PICKER STYLES

.react-datepicker {
    font-size: 1.3rem !important;
}

.react-datepicker__current-month {
    font-size: 1.5rem !important;
}

.react-datepicker__header {
    padding-top: 6px !important;
}

.react-datepicker__navigation {
    top: 13px !important;
}

.react-datepicker__day-name,
.react-datepicker__day {
    margin: 0.5rem !important;
}