
// COLORS

$color-primary: #44949c;
$color-primary-light: #66a7ac;
$color-primary-dark: #025259;



$color-secondary-light: #bfb854;
$color-secondary-dark: #867e09;

$color-tertiary-light: #606186;
$color-tertiary-dark: #2b30bb;

$color-grey-light-1: #f7f7f7;
$color-grey-light-2: #eee;



$color-grey-dark: #777;
$color-grey-dark-2: #999;
$color-grey-dark-3: #333;

$color-white: #fff;
$color-black: #000;


// FONT
$default-font-size: 1.6rem;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;


@mixin clearfix {
    &::after {
        content: "";
        display: table;
        clear: both;
    }
}

@mixin absCenter {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

// MEDIA QUERY MANAGER
/*
0 - 600px:      Phone
600 - 900px:    Tablet portrait
900 - 1200px:   Tablet landscape
[1200 - 1800] is where our normal styles apply
1800px + :      Big desktop

$breakpoint arguement choices:
- phone
- tab-port
- tab-land
- big-desktop

ORDER: Base + typography > general layout + grid > page layout > components

1em = 16px
*/

@mixin respond($breakpoint) {

    @if $breakpoint ==phone-small {
        @media only screen and (max-width: 20em) {
            @content
        }

        ; // 320px
    }

    @if $breakpoint ==phone-medium {
        @media only screen and (max-width: 23.4375em) {
            @content
        }

        ; // 375px
    }

    @if $breakpoint ==phone-large {
        @media only screen and (max-width: 26.5625em) {
            @content
        }

        ; // 425px
    }

    @if $breakpoint == tab-port {
        @media only screen and (max-width: 37.5em) { @content }; // 600px
    }

    @if $breakpoint ==tab-land {
        @media only screen and (max-width: 48em) {
            @content
        }
        ; // 768px
    }


    @if $breakpoint ==small-desktop {
        @media only screen and (max-width: 64em) {
            @content
        }

        ; // 1024px
    }

    @if $breakpoint ==small-desktop-less {
        @media only screen and (max-width: 90em) {
            @content
        }

        ; // 1024px
    }

    @if $breakpoint ==big-desktop {
        @media only screen and (min-width: 90em) {
            @content
        }

        ; // 1440px
    }



    @if $breakpoint ==4k {
        @media only screen and (min-width: 160.00em) {
            @content
        }

        ; // 2560px
    }

    @if $breakpoint ==4kultra {
        @media only screen and (min-width: 175.00em) {
            @content
        }

        ; // 2800px
    }
}


*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
html {
    //  1rem : 10px
    font-size: 62.5%;

    @include respond(phone-small) {
        font-size: 50.00%;
    }


    @include respond(tab-land) {
        font-size: 56.25%;
    }

    @include respond(small-desktop) {
        font-size: 50%;
    }

    @include respond(big-desktop) {
        font-size: 75%;
    }

    @include respond(4k) {
        font-size: 90%;
    }

}

body {
    box-sizing: border-box;
    font-family: 'Sora';
    // padding: 3rem;

    @include respond(tab-port) {
        padding: 0;
    }
}
