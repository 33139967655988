.login {
    min-height: 100vh;
    background-image: linear-gradient(to right, rgba(#c33764, .5), rgba(#1d2671, .5)), url(../img/airplane-2322303_1920.jpg);
    font-size: 1.7rem;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 4rem;

    @include respond(phone-large) {
        padding: 3rem;
    }

    @include respond(phone-medium) {
        padding: 2.5rem;
    }

    @include respond(phone-small) {
        padding: 2rem;
    }


    &__card {
        width: 50%;
        padding: 4rem;
        padding-top: 0;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgba(#ddd, 0.8);
        border-radius: 4rem;
        box-shadow: 1rem 1rem 1rem 1rem rgba(#eee, 0.5);
        z-index: 99;
        margin-top: 3rem;

        @include respond(tab-land) {
            width: 75%;
            padding-top: 4rem;
        }

        @include respond(tab-port) {
            width: 85%;
            padding: 3rem;

        }

        @include respond(phone-large) {
            width: 95%;
            padding: 1.5rem;

        }

        @include respond(phone-medium) {
            width: 99%;
            padding: .8rem;

        }

        &__head {
            padding: 1rem;
            border-radius: 6rem;
            background-color: #111;
            margin-top: -6rem;
            margin-bottom: 4rem;



            &__icon {
                width: 10rem;
                height: 10rem;

                @include respond(phone-large) {
                    width: 6rem;
                    height: 6rem;
                }
            }


        }

        &__group {
            display: flex;
            flex-direction: row-reverse;
            width: 80%;
            margin-bottom: 3rem;

            @include respond(phone-large) {
                width: 95%;


            }

        }

        &__input {
            font-size: 1.5rem;
            font-family: inherit;
            color: #ddd;
            padding: 1.5rem 2rem;
            border-radius: 2px;
            background-color: rgba($color-black, .8);
            border: none;
            border-bottom: 3px solid transparent;
            width: 85%;
            display: block;
            transition: all .3s;

            @include respond(tab-port) {
                width: 100%;
            }

            &:focus {
                outline: none;
                box-shadow: 0 1rem 2rem rgba($color-black, .1);
                border-bottom: 3px solid $color-primary;
            }


            &:focus:invalid {
                border-bottom: 3px solid $color-secondary-dark;
            }

            &::-webkit-input-placeholder {
                color: $color-grey-dark-2;
            }
        }

        &__input:focus~&__label &__label__icon {
            color: $color-tertiary-dark;
        }

        &__label {

            background-color: #000;
            padding: 2rem;

            &__icon {
                transition: all .2s;
                color: $color-white;

                width: 4rem;
                height: 4rem;

                @include respond(phone-large) {
                    width: 2.5rem;
                    height: 2.5rem;
                }
            }

        }

        &__featurecontainer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 60%;

        }

        &__featureitem {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            &__img {
                transition: all .2s;
            }

            span {
                margin-left: 1rem;
                transition: all .2s;
            }

            &:hover &__img {
                color: $color-secondary-dark;
            }

            &:hover span {
                color: $color-secondary-dark;
            }




        }


        &__cautioncontainer {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 70%;

        }

        &__cautionitem {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            @include respond(phone-large) {
               //  display: none;
                // width: 0;
            }

            &__img {
                // display: block;
                transition: all .2s;
            }

            span {
                // display: block;
                margin-left: 1rem;
                transition: all .2s;
                font-size: 1.5rem;
            }

            &:hover &__img {
                color: $color-secondary-dark;
            }

            &:hover span {
                color: $color-secondary-dark;
            }




        }

    }

    &__btncontainer {
        margin-top: 1rem;
        margin-bottom: 3rem;
        width: 90%;
        display: flex;
        justify-content: center;
        align-items: center;


    }

    &__btn {
        width: 80%;
        font-family: inherit;

        font-weight: bold;
        background-color: rgba($color-tertiary-dark, 1);
        color: #fff;
        border-radius: 0;

        box-shadow: 1rem 1rem rgba($color-black, 0.5);
    }


}